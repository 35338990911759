<template>
  <div
    :class="{ 'login-error': animate }"
    class="Login"
    @animationend="animate = false"
  >
    <div class="Login__logo">
      <img src="@/assets/img/kc-logo-panel-blue.png" />
    </div>
    <input
      v-model="email"
      :class="{ 'has-error': email_error }"
      type="email"
      placeholder="Email"
      @keyup.enter="login"
    />
    <input
      v-model="password"
      :class="{ 'has-error': password_error }"
      type="password"
      placeholder="Password"
      @keyup.enter="login"
    />
    <button :disabled="busy" @click="login()">submit</button>
  </div>
</template>

<script>
export default {
  name: 'Login',

  data() {
    return {
      email: null,
      password: null,
      email_error: false,
      password_error: false,
      animate: false,
      busy: false,
    };
  },
  watch: {
    password() {
      if (this.password) {
        this.password_error = false;
      }
    },
    email() {
      if (this.email) {
        this.email_error = false;
      }
    },
  },
  methods: {
    login() {
      if (this.email && this.password) {
        /* eslint-disable */
        const patt = new RegExp(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        /* eslint-enable */
        if (patt.test(this.email)) {
          this.busy = true;
          this.getXAuthToken();
        } else {
          this.email_error = true;
        }
      } else {
        if (!this.email) {
          this.email_error = true;
          this.animate = true;
        }
        if (!this.password) {
          this.password_error = true;
          this.animate = true;
        }
      }
    },
    getXAuthToken() {
      this.$store
        .dispatch('login', { email: this.email, password: this.password })
        .then(() => {
          this.$router.push('/');
        })
        .catch(() => {
          this.email_error = true;
          this.password_error = true;
          this.animate = true;
          this.busy = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/auth/Login.scss';
</style>
